import React, { useState, useEffect } from 'react';
import { calculateDistribution } from './functions/calculateDistribution.js';

const RevenueShareApp = () => {
  const [customerPrice, setCustomerPrice] = useState(100);
  const [productOwnerPercentage, setProductOwnerPercentage] = useState(50);
  const [marketingTasksPercentage, setMarketingTasksPercentage] = useState(10);
  const [distributionModel, setDistributionModel] = useState('equal');
  const [customers, setCustomers] = useState([]);

  const [totalProductOwnerShare, setTotalProductOwnerShare] = useState(0);
  const [totalMarketingShare, setTotalMarketingShare] = useState(0);
  const [totalShareReceived, setTotalShareReceived] = useState(0);

  const [shareholders, setShareholders] = useState([
    { id: 1, share: 0 },
    { id: 2, share: 0 },
    { id: 3, share: 0 },
    { id: 4, share: 0 },
  ]);

  const shareholderPercentages = [
    { id: 1, percentage: 50 }, // 25% for shareholder 1
    { id: 2, percentage: 20 }, // 25% for shareholder 2
    { id: 3, percentage: 20 }, // 25% for shareholder 3
    { id: 4, percentage: 10 }, // 25% for shareholder 4
  ];
  

  const addCustomer = () => {
    const newCustomer = { 
      id: customers.length + 1, 
      payment: customerPrice, 
      shareReceived: 0, 
      weight: 0 // New field for storing the weight
    };
    setCustomers([...customers, newCustomer]);
  };
  

  useEffect(() => {
    if (customers.length > 0) {
        const { updatedCustomers, totalProductOwnerShare, totalMarketingShare, totalShareReceived } = calculateDistribution(
            customers,
            productOwnerPercentage,
            marketingTasksPercentage,
            distributionModel
        );

        setCustomers(updatedCustomers);
        setTotalProductOwnerShare(totalProductOwnerShare);
        setTotalMarketingShare(totalMarketingShare);
        setTotalShareReceived(totalShareReceived);

        const updatedShareholders = shareholders.map(shareholder => {
          const percentageInfo = shareholderPercentages.find(p => p.id === shareholder.id);
          const share = percentageInfo ? (totalProductOwnerShare * percentageInfo.percentage) / 100 : 0;
          return {
            ...shareholder,
            share: share
          };
        });
    
        setShareholders(updatedShareholders);
    }
}, [customers, productOwnerPercentage, marketingTasksPercentage, distributionModel]);

  // Inline CSS styles
  // Style for the table
  const tableStyle = {
    width: '100%', 
    borderCollapse: 'collapse', 
    border: '1px solid black', // Adds a border around the table
    margin: '20px 0', // Adds vertical margin above and below the table
    padding: '10px', // Adds padding around the table content
    // ... include other styles like maxHeight and overflowY if needed
  };

  // Conditional style for the table wrapper
  const tableWrapperStyle = customers.length >= 5 ? {
    width: '100%',
    maxHeight: '450px', // Adjust the height as needed
    overflowY: 'auto',
  } : {};

  

  // const tableStyle = customers.length >= 15 ? {
  //   display: 'block',  // Added to ensure height limitation works
  //   maxHeight: '400px', // Adjust the height as needed
  //   overflowY: 'auto'
  // } : {};

 
  const thTdStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  };
  const alternatingRowStyle = {
    backgroundColor: '#f2f2f2',
  };

  

  return (
    <div style={{ marginLeft: '30px' }}>
  <div>
  <h3>Shareholders' Shares</h3>
  <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
    {shareholders.map(shareholder => (
      <div key={shareholder.id} style={{ marginBottom: '10px', textAlign: 'left' }}> {/* Adjust the margin value as needed */}
        Shareholder {shareholder.id}: ${shareholder.share.toFixed(2)}
      </div>
    ))}
  </div>
</div>


      <div>
        <label>
          Product Price: $
          <input
            type="number"
            value={customerPrice}
            onChange={(e) => setCustomerPrice(parseFloat(e.target.value))}
          />
        </label>
      </div>

      <div>
        <label>
          Product Owner Percentage: {productOwnerPercentage}%
          <input
            type="range"
            min="0"
            max="100"
            value={productOwnerPercentage}
            onChange={(e) => setProductOwnerPercentage(parseFloat(e.target.value))}
          />
        </label>
      </div>

      <div>
        <label>
          Marketing Tasks Percentage: {marketingTasksPercentage}%
          <input
            type="range"
            min="0"
            max="100"
            value={marketingTasksPercentage}
            onChange={(e) => setMarketingTasksPercentage(parseFloat(e.target.value))}
          />
        </label>
      </div>

      <div>
        <label>
          Distribution Model:
          <select value={distributionModel} onChange={(e) => setDistributionModel(e.target.value)}>
            <option value="equal">Equal Distribution</option>
            <option value="bellCurve">Bell Curve Distribution</option>
          </select>
        </label>
      </div>

      <button onClick={addCustomer}>Add Customer</button>

      <div style={tableWrapperStyle}>
        <table style={tableStyle}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={thTdStyle}>Customer ID</th>
            <th style={thTdStyle}>Payment</th>
            <th style={thTdStyle}>Product Owner Share</th>
            <th style={thTdStyle}>Marketing Share</th>
            <th style={thTdStyle}>Share Received</th>
            <th style={thTdStyle}>Weight</th> {/* New column for weight */}
          </tr>
        </thead>
        <tbody>
  {customers.map((customer, index) => (
    <tr key={customer.id} style={index % 2 === 0 ? alternatingRowStyle : null}>
      <td style={thTdStyle}>{customer.id}</td>
      <td style={thTdStyle}>${parseFloat(customer.payment).toFixed(2)}</td>
      <td style={thTdStyle}>${customer.productOwnerShare}</td>
      <td style={thTdStyle}>${customer.marketingShare}</td>
      <td style={thTdStyle}>${parseFloat(customer.shareReceived).toFixed(2)}</td>
      <td style={thTdStyle}>{customer.weight ? customer.weight.toFixed(4) : 'N/A'}</td> {/* Check if weight is defined */}
    </tr>
  ))}
</tbody></table>
      </table>
  </div>
      <div>
        <strong>Total Product Owner Share:</strong> ${totalProductOwnerShare.toFixed(2)}<br />
        <strong>Total Marketing Share:</strong> ${totalMarketingShare.toFixed(2)}<br />
        <strong>Total Share Received by Customers:</strong> ${totalShareReceived.toFixed(2)}
      </div>
      

    </div>
  );
};

export default RevenueShareApp;
