export const calculateDistribution = (customers, productOwnerPercentage, marketingTasksPercentage, distributionModel) => {
    let updatedCustomers = customers.map(customer => ({
        ...customer,
        productOwnerShare: (customer.payment * (productOwnerPercentage / 100)),
        marketingShare: (customer.payment * (marketingTasksPercentage / 100)),
        shareReceived: 0,
        weight: 0
    }));

    const totalCustomers = customers.length;
    const mean = 0;
    const standardDeviation = totalCustomers / 4;

    const normalDistribution = (x) => {
        const exponent = -1 * ((x - mean) ** 2) / (2 * (standardDeviation ** 2));
        return (1 / (standardDeviation * Math.sqrt(2 * Math.PI))) * Math.exp(exponent);
    };

    let runningProductOwnerShare = 0;
    let runningMarketingShare = 0;
    let runningShareReceived = 0;
    let totalBellCurveWeight = 0;

    updatedCustomers.forEach((customer, index) => {
        const remainingShare = customer.payment - (customer.payment * (productOwnerPercentage / 100)) - (customer.payment * (marketingTasksPercentage / 100));
        runningProductOwnerShare += customer.payment * (productOwnerPercentage / 100);
        runningMarketingShare += customer.payment * (marketingTasksPercentage / 100);

        if (distributionModel === 'bellCurve') {
            for (let i = 0; i <= index; i++) {
                const weight = normalDistribution(i);
                updatedCustomers[i].weight = weight;
                totalBellCurveWeight += weight;
            }
        }

        for (let i = 0; i <= index; i++) {
            const bellCurveWeight = distributionModel === 'bellCurve' ? normalDistribution(i) : 1;
            const sharePerCustomer = (remainingShare * bellCurveWeight) / (distributionModel === 'bellCurve' ? totalBellCurveWeight : index + 1);
            updatedCustomers[i].shareReceived += sharePerCustomer;
        }
    });

    // Calculate runningShareReceived after the loop
    runningShareReceived = updatedCustomers.reduce((acc, customer) => acc + customer.shareReceived, 0);

    return {
        updatedCustomers,
        totalProductOwnerShare: runningProductOwnerShare,
        totalMarketingShare: runningMarketingShare,
        totalShareReceived: runningShareReceived
    };
};
